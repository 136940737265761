import { useNavigate } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeProvider";

export default function Header() {
  const navigate = useNavigate();
  const [currentDomain, setCurrentDomain] = useState("");

  const { theme, setTheme } = useContext(ThemeContext);

  const handleLogout = async () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    setCurrentDomain(window.location.hostname);
  }, []);

  return (
    <div className="header">
      <img
        className="header-logo"
        src={
          currentDomain === "mastercardjnk.vouch.club"
            ? theme
              ? "/images/J&KDarkLogo.png"
              : "/images/J&KLightLogo.png"
            : currentDomain === "hccb.vouch.club"
            ? "/images/hccb_logo.png"
            : theme
            ? "/images/usv_logo_dark.png"
            : "/images/usv_logo.png"
        }
        alt=""
        style={{ maxWidth: 80 }}
      />
      <div className="mc-header-buttons-container">
        <a href="tel:+917428793001" className="mc-call-us">
          <div className="ps-sm-3 mr-3 mc-call-us-wrapper">
            <a
              className="mc-form"
              href="https://docs.google.com/forms/d/e/1FAIpQLSc5QgrImwfgRlnmTJYixnp5rSqV6bBjg5nRmP_YKTgAV8l0hA/viewform?usp=dialog"
              target="_blank"
              rel="noreferrer"
            >
              <span>
                <b>Contact Support</b>
              </span>
            </a>
          </div>
        </a>
        <div
          className="mc-theme-toggle-button-container"
          onClick={() => {
            setTheme(!theme);
          }}
        >
          {theme ? (
            <i class="fa-solid fa-sun"></i>
          ) : (
            <i class="fa-solid fa-moon"></i>
          )}
        </div>
        <button className="btn btn-logout" onClick={handleLogout}>
          <i class="fa-solid fa-arrow-right-from-bracket"></i>
          <span className="mc-logout-button-text">Logout</span>
        </button>
      </div>
    </div>
  );
}
