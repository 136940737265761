import { Modal } from "react-bootstrap";

export default function OrderConfirmationModal({
  coupon,
  orderConfirmationModal,
  setOrderConfirmationModal,
}) {
  return (
    <>
      <Modal
        show={orderConfirmationModal}
        onHide={() => setOrderConfirmationModal(false)}
        onBackdropClick={() => setOrderConfirmationModal(false)}
        onClose={() => setOrderConfirmationModal(false)}
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="mc-tracking-modal-content">
            <ul>
              <li>
                <span>
                  Dear Partner,
                  <br />
                  <br />
                  Congratulations! Order has been placed successfully!
                  <br /> <br />
                  <>
                    Note: In order to serve you better, please ensure to capture
                    a maiden unboxing video of the product and share it within
                    24 hours from the time of Delivery to{" "}
                    <span className="ellipse-text blue">
                      usvsupport@almonds.ai
                    </span>{" "}
                    for us to process in requests related to Damaged and
                    Defective Product received.
                    <br /> <br />
                    Team USV
                  </>
                </span>
              </li>
            </ul>

            {false && (
              <ul>
                <li>
                  <span>Order Number</span>
                  <span></span>
                </li>
                <li>
                  <span>Delivery Address</span>
                  <span> Agra, UP - 282001</span>
                </li>
                <li>
                  <span>Status</span>
                  <span>In Transit</span>
                </li>
                <li>
                  <span>Tracking Link</span>
                  <span></span>
                </li>
                <li>
                  <span>Last Updated at</span>
                  <span>{new Date().toISOString()}</span>
                </li>
              </ul>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
