import axios from "axios";
import { baseurl } from "../constants";
export const activateCoupon = async (id, coupon_code) => {
  try {
    const response = await axios.post(
      `${baseurl}/api/user/redeemCoupon/activation`,
      { id, coupon_code },
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.message;
  }
};
