import axios from "axios";
import { baseurl } from "../constants";

export const postModalClass = async () => {
  try {
    const response = await axios.get(`${baseurl}/api/user/modal?q=1`, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
      },
    });
    localStorage.removeItem("welcome_modal");
    return response;
  } catch (err) {
    return err.message;
  }
};
