import axios from "axios";
import { baseurl } from "../constants";
export const getCoupons = async (currentPage) => {
  try {
    const response = await axios.get(
      `${baseurl}/api/user/couponcode?page=${currentPage}&size=${100}`,
      {
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.message;
  }
};
