import { createContext } from "react";
import { useState } from "react";

export const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      {loading && (
        <div className="mc-loader">
          <img src="/images/loading.gif.gif" alt="" />
        </div>
      )}

      <div className={loading && "mc-loader-content-container"}>{children}</div>
    </LoaderContext.Provider>
  );
};
