export default function DigitalCouponRedeemInstructions() {
  return (
    <div className="mc-product-item2">
      <div className="mc-product-image-container2">
        <div class="card" style={{ width: "250px" }}>
          <img className="mc-TC-image" src="images/T&C_image.png" alt="" />
        </div>
      </div>
      <div class="text-content">
        <p style={{ fontWeight: "bold" }}>
          How do I add an e-Gift Card to my Amazon account? There are two modes
          for receiving e-Gift cards:
        </p>
        <p>
          1. <strong>E-mail:</strong> Gift Card ordered through the email
          delivery mode will be delivered to the email address entered by the
          purchaser during the order purchase. You can directly add an e-mail
          Gift Card from your email by clicking on the{" "}
          <strong>Add to my Account button.</strong>
        </p>
        <p>
          2. <strong>Shared via link:</strong> You can directly add a Gift Card
          shared via link by clicking on the{" "}
          <strong>Add to Account button.</strong>
        </p>
        <p>
          You will be asked to login to your Amazon.in account and your Gift
          Card funds will be automatically added to Your Account.
        </p>
        <p>
          You can also add the Gift Card to Balance by visiting{" "}
          <a
            style={{ textDecoration: "none" }}
            href="https://www.amazon.in/gp/css/gc/payment/"
          >
            Add Gift Card{" "}
          </a>{" "}
          Page on Amazon Pay Dashboard. Enter the 15-digit alpha-numeric code
          and click on 'Add Gift Card to Balance'.
        </p>
      </div>
      <div class="additional-content-right">
        <p>
          <strong>Note:</strong> An e-Gift Card (E-mail or Share via link) Code
          is 15 characters long and contains both letters and numbers (for
          example AS2A5-NZ1JX-CZCP5). The Gift Card equivalent value gets added
          to your Amazon Pay balance. You can track the balance of your Gift
          Cards{" "}
          <a
            style={{ textDecoration: "none" }}
            href="https://www.amazon.in/pay/history?ref_=apay_deskhome_ViewStatement"
          >
            here
          </a>
          .
        </p>
        <p>
          A <strong>Gift Card Reference ID</strong> contains 15 numbers (for
          example, 6014 8515 3019 3950). It is different from a{" "}
          <strong>Gift Card Code</strong> and should <strong>not</strong> be
          added to the Add Gift Card section.
        </p>
      </div>
    </div>
  );
}
